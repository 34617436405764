import { LazyLoader } from 'components'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { removeAuthToken } from 'library/auth'
import { useHistory } from 'react-router-dom'
import { showAlert } from 'utils'

function Logout() {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    logout()
  }, [])

  function logout() {
    dispatch({ type: 'RESET_STATE' })
    removeAuthToken()

    setTimeout(() => {
      history.replace('/login')
      showAlert('Your are successfully logged out', 'success')
    }, 1000)
  }

  return (
    <LazyLoader />
  )
}

export default Logout
