import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap"
import TextInput from "components/Authenticated/TextInput";
import Loader from "components/Loader";
import { editCategory } from 'store/Categories/api'
import { showAlert } from "utils";

function Edit({ close, category, onSuccess }) {
  let show = !!category

  const [loading, setLoading] = useState(false)

  const formikForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('category name is required')
    }),
    onSubmit: async (values, actions) => {
      setLoading(true)

      try {
        await editCategory(category.id, values)
        showAlert("Category updated successfully", 'success')
        onSuccess({ ...category, ...values })
        close()
      } catch (error) {
        let { errors } = error

        if (errors)
          actions.setErrors(errors)
      }

      setLoading(false)
    }
  });

  useEffect(() => {
    resetForm();
  }, [show])

  let { resetForm, handleBlur, handleSubmit, handleChange, values, touched, errors } = formikForm

  return (
    <Modal size="lg" centered show={show} onHide={close}>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
        return false;
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TextInput
            disabled={loading}
            className="mb-2"
            type="text"
            placeholder="Name"
            name={'name'}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            error={errors.name}
            touched={touched.name}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit">{loading ? <Loader /> : "Save"}</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Edit
