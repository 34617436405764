const AUTH_KEY = 'access_token';
const USER_TYPE = 'user_type';
const REFRESH_KEY = 'refresh_token';

export function setAuthToken(token) {
  return localStorage.setItem(AUTH_KEY, token);
}

export function setUserType(type) {
  return localStorage.setItem(USER_TYPE, type);
}

export function getUserType() {
  return localStorage.getItem(USER_TYPE);
}

export function getAuthToken() {
  return localStorage.getItem(AUTH_KEY);
}

export function removeAuthToken() {
  return localStorage.removeItem(AUTH_KEY);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_KEY);
}

export function setRefreshToken(token) {
  return localStorage.setItem(REFRESH_KEY, token);
}

export function isSignedIn() {
  let authToken = getAuthToken();

  return (authToken != null) ? true : false
}

export function isCustomer() {
  return getUserType() == 'customer'
}

export function isAdmin() {
  return getUserType() == 'admin'
}

export const passwordErrors = [
  'Your password must contain at least 8 characters.',
  'Your password can’t be a commonly used password.',
  'Your password can’t be entirely numeric.'
]
