import { Fragment, useEffect, useState } from "react"
import { validatePassword as validateFieldApi } from "store/Auth/api"

function TextInput({
  className = "",
  error: formError,
  touched,
  min = null,
  type,
  value,
  disabled,
  name,
  placeholder = "",
  title = "",
  controlled = false,
  onChange, onBlur: formBlur = null
}) {
  const [error, setError] = useState(formError)

  async function validateField(e) {
    try {
      await validateFieldApi({ [e.target.name]: e.target.value })
      setError([])

    } catch ({ errors }) {
      setError(errors[e.target.name])
    }
  }

  function onBlur(e) {
    if (controlled)
      validateField(e)
    else if (formBlur)
      formBlur(e)
  }

  useEffect(() => {
    setError(formError)
  }, [formError])

  return (
    <div className={className}>
      <label htmlFor={name} className="form-label">{title || placeholder}</label>

      <input
        disabled={disabled}
        name={name}
        type={type}
        min={min}
        value={value}
        className={`form-control ${(error && touched) ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        id={name}
        onChange={onChange}
        onBlur={onBlur}
      />
      {
        error && touched &&
        <div className={`invalid-feedback ${controlled ? "text-dark" : 'text-danger'}`}>
          {
            controlled ?
              <Fragment>
                {
                  error.length > 0 ?
                    <ul>
                      {
                        error.map(_message => <li key={`${_message}-${name}`}>
                          {_message}
                        </li>
                        )
                      }
                    </ul>
                    :
                    <span>
                      <p className="fa-solid fa-check mx-1"></p>
                      Looks good
                    </span>
                }
              </Fragment>
              :
              <Fragment>
                {error}
              </Fragment>
          }
        </div>
      }
    </div>
  )
}

export default TextInput
