import User from 'store/Users/reducers'
import Util from 'store/Utils/reducers'
import Admin from 'store/Admins/reducers'
import { combineReducers } from 'redux'

const appReducer = combineReducers({
  User,
  Util,
  Admin
})

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
