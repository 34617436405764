import { combineReducers } from "redux"
import { Map } from 'immutable'

const INIT_STATE = {
  loading: false,
}

export default combineReducers({
  utils: utils
})


function utils(state = Map(INIT_STATE), action) {
  switch (action.type) {
    case 'SET_UTILS_LOADER':
      return state.set("loading", action.payload);
    default:
      return state;
  }
}
