import React from "react";
import { Button } from "react-bootstrap";

function CategoryTasks({ categories, setCategory, setTask, setDeletableTask }) {

  return (
    <React.Fragment>
      {
        categories.map(_category => (
          <li
            key={`task-${_category.id}`}
            className="shadow-sm rounded single-form"
            style={{ height: "fit-content" }}
          >
            <div className="form-header">
              <a
                data-bs-toggle="collapse"
                href={`#${_category.id}`}
                role="button"
                aria-expanded="true"
                aria-controls={_category.id}
              >
                {_category.name}'s Tasks

                <i className="fa-solid fa-chevron-up"></i>
              </a>
            </div>

            <div className="collapse show" id={_category.id}>
              <div className="card card-body gap-3">
                <div className="d-flex flex-wrap gap-2">
                  {
                    _category.tasks.map(_task => <div key={`task-${_task.id}`} className="btn-group rounded-pill" role="group">
                      <button
                        type="button"
                        onClick={() => setTask({ ..._task, category: _category })}
                        className="btn btn-light">
                        {_task.area?.name || _task.message}
                      </button>

                      <button onClick={() => setDeletableTask(_task)} type="button" className="btn btn-light">
                        <i className="fa-regular fa-trash-can text-danger"></i>
                      </button>
                    </div>
                    )}
                </div>

                <span>
                  <Button onClick={() => setCategory(_category)} variant="outline-success" className="px-3 py-1 rounded text-primary">
                    Add a new one
                  </Button>
                </span>
              </div>
            </div>
          </li>
        ))}
    </React.Fragment>
  )
}

export default CategoryTasks;
