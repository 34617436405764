import loadable from "@loadable/component";
import { LazyLoader } from 'components'
import { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { isSignedIn, isAdmin } from "library/auth";
import { showAlert } from "utils";

export default function Loadable({
  page,
  ...rest
}) {

  const LoadablePage = loadable(({ page }) => import(`navigations/${page}`), {
    fallback: <LazyLoader />,
    cacheKey: ({ page }) => page
  });

  let _isAdmin = isAdmin()
  if (_isAdmin)
    showAlert("You are not authorized to access this page.", 'error')

  return (
    <Route
      {...rest}
      exact
      render={props => {
        return !isSignedIn() ?
          <Redirect to={'/login'} /> :
          _isAdmin ?
            <Redirect to={'/'} /> :
            <Suspense fallback={<LazyLoader />}>
              <div className="auth">
                <LoadablePage page={page} {...props} />
              </div>
            </Suspense>
      }
      } />
  )
}
