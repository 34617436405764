import loadable from "@loadable/component";
import { LazyLoader } from 'components'
import { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { isSignedIn } from "library/auth";

export default function Loadable({
  page,
  ...rest
}) {

  const LoadablePage = loadable(({ page }) => import(`navigations/${page}`), {
    fallback: <LazyLoader />,
    cacheKey: ({ page }) => page
  });

  return (
    <Route
      {...rest}
      exact
      render={props => {
        return !isSignedIn() ?
          <Redirect to={'/login'} /> :
          <Suspense fallback={<LazyLoader />}>
            <div className="auth">
              <LoadablePage page={page} {...props} />
            </div>
          </Suspense>
      }
      } />
  )
}
