import { Fragment, useEffect, useState } from "react"

function TextArea({
  className,
  error: formError,
  touched,
  value,
  disabled,
  name,
  placeholder = "",
  title = "",
  onChange, 
  onBlur,
  rows=null
}) {
  const [error, setError] = useState(formError)

  useEffect(() => {
    setError(formError)
  }, [formError])

  return (
    <div className={className}>
      <label htmlFor={name} className="form-label">{title || placeholder}</label>

      <textarea
        disabled={disabled}
        name={name}
        value={value}
        className={`form-control ${(error && touched) ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        id={name}
        onChange={onChange}
        onBlur={onBlur}
        rows={rows}
      />

      {
        error && touched &&
        <div className="invalid-feedback text-danger">
          {error}  
        </div>
      }
    </div>
  )
}

export default TextArea
