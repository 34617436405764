import loadable, { lazy } from "@loadable/component";
import { LazyLoader, Loader } from 'components'
import { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { isSignedIn, isCustomer } from "library/auth";
import { showAlert } from "utils";
const Layout = lazy(() => import("layouts/Simple"))

export default function Loadable({
  page,
  header = "",
  ...rest
}) {

  const LoadablePage = loadable(({ page }) => import(`navigations/${page}`), {
    fallback: <div className="text-center"><Loader /></div>,
    cacheKey: ({ page }) => page
  });

  let _isCustomer = isCustomer()
  if (_isCustomer)
    showAlert("You are not authorized to access this page.", 'error')

  return (
    <Route
      {...rest}
      exact
      render={props => {
        return !isSignedIn() ?
          <Redirect to={'/login'} /> :
          _isCustomer ?
            <Redirect to={'/'} /> :
            <Suspense fallback={<LazyLoader />}>
              <Layout header={header || page}>
                <LoadablePage page={page} {...props} />
              </Layout>
            </Suspense>
      }
      } />
  )
}
