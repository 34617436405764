import loadable from "@loadable/component";
import { LazyLoader } from 'components'
import { Suspense } from "react";
import { Route } from "react-router-dom";

export default function Loadable({
  page,
  ...rest
}) {

  const LoadablePage = loadable(({ page }) => import(`navigations/${page}`), {
    fallback: <LazyLoader />,
    cacheKey: ({ page }) => page
  });

  return (
    <Route
      {...rest}
      exact
      render={props =>
        <Suspense fallback={<LazyLoader />}>
          <div className="auth">
            <LoadablePage page={page} {...props} />
          </div>
        </Suspense>
      } />
  )
}
