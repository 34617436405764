import { Fragment, useState } from "react"
import { Loader } from "components"
import { deleteCategory } from "store/Categories/api"
import { showAlert } from "utils"

function Category({ category, onDelete, selectCategory }) {
  const [loading, setLoading] = useState(false)

  async function _deleteCategory() {
    setLoading(true)
    try {
      await deleteCategory(category.id)
      onDelete(category.id)
      showAlert("Category is deleted", 'success')
    } catch (error) {
      showAlert("Something went wrong deleting category", 'error')
    }
    setLoading(false)
  }

  return (
    <li
      className="shadow-sm rounded mb-1 bg-white col-12 p-3 d-flex justify-content-between align-items-center"
    >
      <div>
        <i className={`fa-solid fa-${category.tasks.length == 0 ? "circle" : "check"} text-primary me-3`}></i>

        <span>{category.name}</span>
      </div>

      {
        !category.is_system_generated &&
        <div className="d-flex flex-nowrap">
          <i className="fa-solid fa-pen-to-square " role="button" onClick={() => selectCategory(category)}></i>

          {
            category.is_deletable && category.tasks.length == 0 &&
            <Fragment>
              {
                loading ? <Loader size="sm" className="ms-3" />
                  : <i onClick={_deleteCategory} className="fa-regular fa-trash-can text-danger ms-3" role="button"></i>
              }
            </Fragment>
          }
        </div>
      }
    </li>
  )
}

export default Category
