import axios from 'axios';

import { getAuthToken } from 'library/auth'
import { server } from 'configs/constants'
import { showAlert } from 'utils';

const service = axios.create({
    baseURL: new URL('/api/v1', server).href,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
});

function isAuthError(error) {
    const { response } = error;
    return response && response.status === 401;
}

function isForbidden(error) {
    const { response } = error;
    return response && response.status === 403;
}

function isBadRequest(error) {
    const { response } = error;
    return response && response.status === 400;
}

function isServerCrashes(error) {
    const { response } = error;
    return response && response.status === 500;
}

service.interceptors.request.use(async (config: any) => {
    const token = await getAuthToken();
    const url = config.url.match(/\/$/) ? config.url : `${config.url}/`;

    if (token) {
        return {
            ...config,
            url,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            },
        };
    }

    return {
        ...config,
        url,
        headers: config.headers,
    };
});

// Response Interceptor
service.interceptors.response.use(
    ({ data }) => data,
    async (error) => {
        let errorMessage = ""
        const { response = {} } = error;

        if (!error)
            return Promise.reject('There was an error.');

        if (isAuthError(error)) {
            window.location.href = '/logout'
            return Promise.reject('Session expired!');
        }

        if (isForbidden(error)) {
            window.location.href = '/'
            return Promise.reject('Forbidden!');
        }

        // for alert service
        if (isBadRequest(error) && response.data?.errors?.non_field_errors)
            errorMessage = response.data?.errors?.non_field_errors[0]
        if (isServerCrashes(error))
            errorMessage = "Server crashes!"
        else if (error.message && !error.response)
            errorMessage = error.message

        if (axios.isCancel(error))
            return Promise.reject(error);

        if (errorMessage)
            showAlert(errorMessage, 'error')

        return Promise.reject(response.data || error);
    },
);

const cancelable = {};

export default function ({ cancelPrevious, ...config }) {
    if (cancelPrevious) {
        const key = `${config.method}-${config.url}`;
        const cancel = cancelable[key];
        if (cancel) cancel('request-cancel');

        return service({
            ...config,
            cancelToken: new axios.CancelToken((cancelRequest) => {
                cancelable[key] = cancelRequest;
            }),
        });
    }
    return service(config);
}
