import { Fragment } from 'react';
import Router from 'routes'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Slide, ToastContainer } from 'react-toastify';

function App() {
  return (
    <Fragment>
      <Router />

      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        transition={Slide}
        closeButton={<Fragment />}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </Fragment>
  )
}

export default App
