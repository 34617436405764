import { redirectURI } from "utils/apiHelper";
import request from "library/v1/request";


export const authenticate = async (data) => {
  return await request({
    url: "authenticate/",
    method: "POST",
    data
  });
};

export function twoFactorAuth(uidb64, token, data) {
  return request({
    url: `authenticate/mfa/${uidb64}/${token}`,
    method: 'post',
    data: data
  });
}

export const signup = async (data) => {
  return await request({
    url: "signup/",
    method: "POST",
    data: { ...data, redirect_uri: redirectURI('confirming_user') }
  });
};

export const confirmUser = async (uidb64, token) => {
  return await request({
    url: `confirm_user/${uidb64}/${token}`,
    method: "POST"
  });
};

export const forgetPassword = async (data) => {
  return await request({
    url: "forget_password/",
    method: "POST",
    data: { ...data, redirect_uri: redirectURI('reset_password') }
  });
};

export const resetPassword = async (uidb64, token, data) => {
  return await request({
    url: `reset_password/${uidb64}/${token}`,
    method: "POST",
    data
  });
};

export const googleAuthenticate = async (data) => {
  return await request({
    url: "google_authenticate/",
    method: "POST",
    data
  });
};

export const facebookAuthenticate = async (data) => {
  return await request({
    url: "facebook_authenticate/",
    method: "POST",
    data
  });
};


export const validatePassword = async (data) => {
  return await request({
    url: "validate_password/",
    method: "POST",
    data
  });
};

