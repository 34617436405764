import moment from 'moment'
import { toast } from 'react-toastify'

export function showAlert(message: string, type: 'success' | 'warning' | 'error') {
  if (type == 'success')
    toast.success(message)
  else if (type == 'warning')
    toast.warn(message)
  else if (type == 'error')
    toast.error(message)
}

export function dateFormat(date: string) {
  return moment(date).format(dateF)
}

export function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) { return m.toUpperCase(); });
}

export const dateF = 'YYYY-MM-DD'
export const timeF = 'HH:mm'
export const dateTimeF = 'YYYY-MM-DDTHH:mm:ssZ'
