import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import immutableTransform from 'redux-persist-transform-immutable';
import thunk from 'redux-thunk';
import rootReducer from 'store/reducers'

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
  blacklist: ['Util']
};

const persistedReducer = persistReducer(persistConfig, rootReducer)
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const middlewares = [thunk]

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))
const persistor = persistStore(store);
export { store, persistor }
