import { combineReducers } from "redux"
import { Map } from 'immutable'

const INIT_STATE = {
  stats: null
}

export default combineReducers({
  admins: admins
})


function admins(state = Map(INIT_STATE), action) {
  switch (action.type) {
    case 'SET_STATS':
      return state.set("stats", action.payload);

    default:
      return state;
  }
}
