import { Fragment } from "react";
import Category from "./Category";

function Categories({ categories, setCategories, selectCategory }) {

  function onDelete(_categoryId) {
    setCategories(_categories => _categories.filter(c => c.id != _categoryId))
  }

  return (
    <Fragment>
      {
        categories.map((category, _index: number) => (
          <Category
            key={`category-${_index.toString()}`}
            category={category}
            onDelete={onDelete}
            selectCategory={selectCategory}
          />
        ))
      }
    </Fragment>
  )
}

export default Categories;
