import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Loadable from "components/Loadable";
import Auth from "components/Loadable/Auth";
import Protected from "components/Loadable/Protected";
import Customer from "components/Loadable/Customer";
import App from "components/Loadable/App";
import Logout from "navigations/Logout";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Auth page="Login" path="/login" />
        <Auth page="Signup" path="/signup" />
        <Auth page="ForgetPassword" path="/forget_password" />
        <Auth page="ForgetPassword/Reset" path="/reset_password/:uidb64/:token" />
        <Auth page={"Signup/Confirm"} path={`/confirming_user/:uidb64/:token`} />
        <Auth page={"Signup/Accept"} path={`/invite/accept/:uidb64/:token`} />
        <Auth page={"Signup/CustomerAccept"} path={`/customers/invite/accept/:uidb64/:token`} />
        <Protected page="Profile" path="/profile" />
        <Customer page="Information" path="/information" />
        <Loadable page="Root" path="/welcome" />
        <App page="Dashboard" path="/dashboard" header="Admin Home" />
        <App page={"Templates/Edit"} path="/templates/:id/edit" header="Template Setup" />
        <App page="Templates" path="/templates" />

        <Route exact path={'/logout'} component={Logout} />
        <Redirect path="*" to='/welcome' />
      </Switch>
    </BrowserRouter>
  );
}

export default Router
