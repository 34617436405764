import request from "library/v1/request";

export const editCategory = async (id, data) => {
  return await request({
    url: `service_templates/task_categories/${id}/`,
    method: "PATCH",
    data
  });
};

export const createCategory = async (data) => {
  return await request({
    url: `service_templates/task_categories/`,
    method: "POST",
    data
  });
};

export const deleteCategory = async (id) => {
  return await request({
    url: `service_templates/task_categories/${id}/`,
    method: "DELETE"
  });
};
