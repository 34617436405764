import { combineReducers } from "redux"
import { Map } from 'immutable'

const INIT_STATE = {
  user: null
}

export default combineReducers({
  users: users
})


function users(state = Map(INIT_STATE), action) {
  switch (action.type) {
    case 'SET_USER':
      return state.set("user", action.payload);

    default:
      return state;
  }
}
